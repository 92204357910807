import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div>
        <header
          id="header"
          className="header fixed-top"
          style={{ height: "100px", backgroundColor: "#F3FBF7" }}
        >
          <div
            className="container-fluid container-xl d-flex align-items-center justify-content-between"
            style={{ height: "100px" }}
          >
            <Link to="/" className="logo d-flex align-items-center">
              <img
                src="assets/img/eglipsesolution logo.png"
                alt="logo"
                style={{ height: "250px", width: "150px" }}
              />
            </Link>

            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <Link to="/" className="nav-link scrollto active">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="/About" className="nav-link scrollto ">
                    About
                  </Link>
                </li>

                <li>
                  <Link to="/Service">Services</Link>
                </li>
                {/* <li >
                  <Link to="/Sample">
                    Our sample 
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="nav-link scrollto" to="/Testimonials">
                    Testimonial
                  </Link>
                </li> */}
                <li>
                  <Link className="getstarted scrollto" to="/ContactUs">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
        </header>
        {/* <div className="container-fluid  pt-3 d-none d-lg-block" style={{backgroundColor:"white"}} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                            <div className="d-inline-flex align-items-center">
                              <p>
                                <Link to="mailto:mani.tritonpublisher@gmail.com"><i className="bi bi-envelope mr-2"></i>mani.tritonpublisher@gmail.com</Link></p>
                                <p className="text-body px-3">|</p>
                                <p>
                                <Link to="tel:+91 9500116829"><i className="bi bi-phone mr-2"></i>+91 9500116829</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center text-lg-right">
                            <div className="d-inline-flex align-items-center">
                              
                                <Link className="text-primary px-3" to="">
                                    <i className="bi bi-facebook"></i> 
                                </Link>
                                <Link className="text-primary px-3" to="">
                                    <i className="bi bi-twitter"></i> 
                                </Link>
                                <Link className="text-primary px-3" to="">
                                    <i className="bi bi-linkedin"></i> 
                                </Link>
                                <Link className="text-primary px-3" to="">
                                    <i className="bi bi-instagram"></i> 
                                </Link>
                                <Link className="text-primary pl-3" to="">
                                    <i className="bi bi-youtube"></i> 
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid position-relative nav-bar p-0" style={{backgroundColor: "#F3FBF7" }}>
            <div
            className="container-fluid container-xl d-flex align-items-center justify-content-between"
            style={{ height: "100px" }}
          >
            <Link to="/" className="logo d-flex align-items-center">
              <img
                src="assets/img/logo (1).png"
                alt=""
                style={{ height: "70px", width: "100px" }}
              />
            </Link>

            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <Link to="/" className="nav-link scrollto active">
                    Home
                  </Link>
                </li>

                <li>
                  <Link to="/About" className="nav-link scrollto ">
                  About
                  </Link>
                </li>
              
                <li >
                  <Link to="/Service">
                    Services 
                  </Link>
                </li>
                <li >
                  <Link to="/Sample">
                    Our sample 
                  </Link>
                </li>
                <li>
                  <Link className="nav-link scrollto" to="/Testimonials">
                    Testimonial
                  </Link>
                </li>
                <li>
                  <Link className="getstarted scrollto" to="/ContactUs">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
                </div> */}
      </div>
    </>
  );
};

export default Header;
