import "./App.css";

import Header from "./components/navbar/Header";
import Footer from "./components/navbar/Footer";
import ContactUs from "./components/pages/ContactUs";
import Home from "./components/pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Service from "./components/pages/Service";

import About from "./components/pages/About";

import Testimonials from "./components/pages/Testimonials";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Header" element={<Header />}></Route>
          <Route path="/About" element={<About />}></Route>

          <Route path="/Testimonials" element={<Testimonials />}></Route>
          <Route path="/Service" element={<Service />}></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
          <Route path="/Footer" element={<Footer />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
